import FullScreenLoading from "@/components/FullScreenLoading";
import { AppState, appState } from "@/state/user";
import { hexToSpaceSeparated } from "@/utils/color";
import { internationalizeVietnamesePhone } from "@/utils/phone";
import { trim } from "lodash-es";
import { PropsWithChildren, useEffect } from "react";
import { useRecoilState } from "recoil";
import * as Sentry from "@sentry/react";

type Props = PropsWithChildren<unknown>;

type AvailableSearchParams = {
  refresh_token: string; // auth data
  access_token: string; // auth data
  device_token: string; // auth data
  slug: string; // company data
  light_color: string; // theme data
  default_color: string; // theme data
  dark_color: string; // theme data
  phone_number: string; // user data
  employee_code: string; // user data
  phone_number_change: string; // user data
  error_message: string; // error data
  onboarding_flow: string; // onboarding data
};

/**
 * Initializes the application state from the URL search params.
 * This component is used to initialize the application state from the URL search params.
 * It will update the application state with the search params and set the initialized state to true.
 *
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The initialized application state.
 */
function AppStateInitializer({ children }: Props): JSX.Element {
  const [{ initialized }, setState] = useRecoilState<AppState>(appState);

  const updateState = (params: AvailableSearchParams, ignore = false) => {
    if (ignore) return;
    const {
      refresh_token: refreshToken,
      access_token: accessToken,
      device_token: deviceToken,
      light_color: lightColor,
      default_color: defaultColor,
      dark_color: darkColor,
      phone_number: phoneNumber,
      employee_code: employeeCode,
      phone_number_change: phoneNumberChange,
      error_message: errorMessage,
      onboarding_flow: onboardingFlow,
      slug,
    } = params;

    if (slug) {
      setState((prevState) => ({
        ...prevState,
        refreshToken,
        accessToken,
        deviceToken,
        slug,
        employeeCode,
        phoneNumberChange: phoneNumberChange === "true", // Convert string to boolean
        errorMessage,
        onboardingFlow,
        phoneNumber: phoneNumber
          ? internationalizeVietnamesePhone(trim(phoneNumber))
          : prevState.phoneNumber,
        lightColor: lightColor
          ? hexToSpaceSeparated(lightColor)
          : prevState.lightColor,
        defaultColor: defaultColor
          ? hexToSpaceSeparated(defaultColor)
          : prevState.defaultColor,
        darkColor: darkColor
          ? hexToSpaceSeparated(darkColor)
          : prevState.darkColor,
        initialized: true,
      }));
    } else {
      // Handle error: slug not found
      setState((prevState) => ({
        ...prevState,
        errorMessage,
        phoneNumber: phoneNumber
          ? internationalizeVietnamesePhone(trim(phoneNumber))
          : prevState.phoneNumber,
        lightColor: lightColor
          ? hexToSpaceSeparated(lightColor)
          : prevState.lightColor,
        defaultColor: defaultColor
          ? hexToSpaceSeparated(defaultColor)
          : prevState.defaultColor,
        darkColor: darkColor
          ? hexToSpaceSeparated(darkColor)
          : prevState.darkColor,
        initialized: true,
      }));
    }
  };

  useEffect(() => {
    let ignore = false;

    try {
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );
      updateState(params as AvailableSearchParams, ignore);
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          error,
        },
      });
    }

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only on initial render

  if (!initialized) {
    return <FullScreenLoading isOpen />;
  }
  return <>{children}</>;
}

export default AppStateInitializer;
