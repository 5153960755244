/* eslint-disable react-refresh/only-export-components */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
  from,
} from "@apollo/client";
import React, { FC, useCallback, useEffect, useState } from "react";
import { onError } from "@apollo/client/link/error";
import { useAppState } from "./hooks/useAppState";
import { exchangeAccessToken, graphqlErrorParser } from "./helpers";
import ApolloLinkTimeout from "apollo-link-timeout";
import { setContext } from "@apollo/client/link/context";
import { StrictTypedTypePolicies } from "./apollo/apollo-helpers";
import { vuiStylePagination } from "./utils/pagination";
import { get } from "lodash-es";
import toast from "react-hot-toast";
import { getState } from "./utils/storage";
import { AppState, appStateKey } from "./state/user";
import { API_URI, PLATFORM } from "./constants";

const whiteListOperationName = ["login", "requestChangePassword"]; // Do not need to re-authenticate with these operations

interface ApolloLayerProps {
  children?: React.ReactNode;
}

const typePolicies: StrictTypedTypePolicies = {
  ProductResolved: {
    keyFields: ["code"], // because of it doesn't have id
  },
  User: {
    fields: {
      messages: vuiStylePagination(["query", ["filter"]]),
    },
  },
  Employment: {
    fields: {
      loanApplications: vuiStylePagination([
        "query",
        ["orderBy", "orderDirection", "filter"],
      ]),
    },
  },
};

const cache = new InMemoryCache({ typePolicies });

const timeoutLink = new ApolloLinkTimeout(30_000);

const debugOTPLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { data, extensions } = response;

    let otp = null;

    if (get(data, "requestLogin.debug_otp", null)) {
      otp = data?.requestLogin.debug_otp;
    }

    if (get(data, "requestChangePassword.debug_otp", null)) {
      otp = data?.requestChangePassword.debug_otp;
    }

    if (get(data, "requestChangePasswordV2.debug_otp", null)) {
      otp = data?.requestChangePasswordV2.debug_otp;
    }

    if (get(data, "sendUserOtpV2.debug_otp", null)) {
      otp = data?.sendUserOtpV2.debug_otp;
    }

    if (get(data, "sendUserOtpV2.debug_otp", null)) {
      otp = data?.sendUserOtpV2.debug_otp;
    }

    if (get(extensions, "x-debug-otp", null)) {
      otp = extensions?.["x-debug-otp"];
    }

    if (otp) {
      toast(`Enter ${otp} to continue`);
    }

    return response;
  });
});

const setAuthorizationLink = setContext((_, previousContext) => {
  const { headers } = previousContext;
  const { accessToken } = getState<AppState>(appStateKey);

  return {
    headers: {
      "x-debug-otp": window.DEBUG_OTP || true,
      "x-format-money": "json",
      "x-platform": PLATFORM,
      "Accept-Language": "vi-VN",
      authorization: accessToken ? `Bearer ${accessToken}` : "",
      ...headers,
    },
  };
});

const ApolloLayer: FC<ApolloLayerProps> = ({ children }) => {
  const [client, setClient] = useState<ApolloClient<unknown> | undefined>(
    undefined
  );

  const { clear: clearAppState, setState } = useAppState();

  const handleClearToken = useCallback(() => {
    clearAppState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reAuthentication = (operation: Operation, forward: NextLink) => {
    try {
      const { refreshToken } = getState<AppState>(appStateKey);
      if (!refreshToken) {
        handleClearToken();
        return;
      }

      return new Observable((observer) => {
        exchangeAccessToken(refreshToken)
          .then((refreshResponse) => {
            if (refreshResponse && refreshResponse.accessToken) {
              // update new token
              if (refreshResponse.refreshToken) {
                setState((prevState) => ({
                  ...prevState,
                  accessToken: refreshResponse.accessToken,
                  refreshToken: refreshResponse.refreshToken,
                }));
              }

              // update context with new token
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  authorization:
                    `Bearer ${refreshResponse.accessToken}` || null,
                },
              }));
            }
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            toast.error(graphqlErrorParser(error), {
              duration: 15_000,
              id: "reAuthenticationError",
            });
            handleClearToken();
            observer.error(error);
          });
      });
    } catch (error) {
      toast.error(graphqlErrorParser(error), {
        duration: 15_000,
        id: "reAuthenticationError",
      });
      handleClearToken();
      return;
    }
  };

  const errorLink = onError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ graphQLErrors, networkError, operation, forward }): any => {
      if (graphQLErrors) {
        graphQLErrors.map(({ extensions }) => {
          if (
            extensions?.applicationCode === "ERR_UNAUTHORIZED" &&
            !whiteListOperationName.includes(operation.operationName)
          ) {
            return reAuthentication(operation, forward);
          }
        });
      }

      if (networkError) {
        // captureException(networkError);
        if (
          "statusCode" in networkError &&
          networkError.statusCode === 401 &&
          !whiteListOperationName.includes(operation.operationName)
        ) {
          return reAuthentication(operation, forward);
        }
      }

      const context = operation.getContext();
      if (
        context &&
        context.response &&
        context.response.status === 401 &&
        !whiteListOperationName.includes(operation.operationName)
      ) {
        return reAuthentication(operation, forward);
      }
    }
  );

  const initializeApolloClient = useCallback(async () => {
    try {
      const apolloClient = new ApolloClient({
        cache,
        link: from([
          timeoutLink,
          errorLink,
          setAuthorizationLink,
          debugOTPLink,
          new HttpLink({
            uri: API_URI,
            credentials: "include",
          }),
        ]),
        connectToDevTools: import.meta.env.MODE === "development",
      });

      setClient(apolloClient);
    } catch (e) {
      console.warn(e);
    }
  }, [errorLink]);

  useEffect(() => {
    initializeApolloClient();

    return () => {
      setClient(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {client ? (
        <ApolloProvider client={client}>{children}</ApolloProvider>
      ) : null}
    </>
  );
};

export default ApolloLayer;
