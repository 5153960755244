import { analytics } from "@/services/segment";
import { EventProperties, SegmentEvent } from "@segment/analytics-next";

export enum ANALYTIC_CATEGORIES {
  Home = "home",
  LoginCredential = "login_credential",
  RegisterCredential = "register_credential",
  Attendance = "attendance",
  LoanApplication = "loan_application",
  LoanHistory = "loan_history",
  Faq = "faq",
  TermCondition = "term_condition",
  HelpDesk = "help_desk",
  EmployeeInfo = "employee_info",
  Setting = "setting",
  Payslip = "payslip",
  Message = "message",
  PhoneCard = "phonecard_v1", // it should be phonecard, but we have to keep it for backward compatibility
  TopUp = "topup_v1", // it should be top_up, but we have to keep it for backward compatibility
  ForgotPassword = "forgot_password",
  Walkthrough = "walkthrough",
  Voucher = "voucher",
  DataConsent = "data_consent",
}

export const ANALYTIC_EVENT_NAMES = {
  [ANALYTIC_CATEGORIES.Walkthrough]: {
    press_next: "Walkthrough Next Button Pressed", // Done
  },
  [ANALYTIC_CATEGORIES.Home]: {
    press_home: "Home Pressed", // Done
  },
  [ANALYTIC_CATEGORIES.LoginCredential]: {
    submit_phone: "Login Credential: Phone Submitted", // Done
  },
  [ANALYTIC_CATEGORIES.RegisterCredential]: {
    submit_phone: "Phone Submitted", // Done
    submit_otp: "Register Credential: OTP Submitted", // Done
    create_password: "Register Credential: Password Created", // Done
    confirm_password: "Register Credential: Password Confirmed", // Done
    link_employment_success: "Register Succeeded", // Done
    submit_otp_toc: "Register Credential: TOC Accepted", // Done
    submit_kyc_failure: "KYC Submitted Failed", // Done
    submit_kyc: "KYC Submittted", // Done  // it should be KYC Submitted, but we have to keep it for backward compatibility
    load_toc_screen: "Register Credential: TOC Screen Loaded", // Done
    load_toc_success: "Register Credential: TOC Succeeded", // Done
    load_toc_failure: "Register Credential: TOC Failed", // Done
    press_agreement: "Register Credential: TOC Agreement Pressed", // Done
  },
  [ANALYTIC_CATEGORIES.Attendance]: {
    press_attendance: "Attendance Started", // Done
    press_info: "Attendance Info Pressed",
    press_date: "Attendance: Date Presed", // Done
    press_month: "Attendance: Month Changed", // Done
    press_withdrawal: "Attendance: EWA Started", // Done
  },
  [ANALYTIC_CATEGORIES.LoanApplication]: {
    press_info: "EWA Info Pressed",
    press_mask_income: "Mask Income Pressed", // Done
    press_withdrawal: "EWA Started", // Done
    commit_slider_loan: "EWA Slider Committed", // Done
    press_money_button: "Money Button Pressed", // Done
    press_continue_transaction: "EWA Continued", // Done
    press_confirm_transaction: "EWA Confirmed", // Done
    submit_password: "EWA Password Submitted", // Done
    withdrawal_success: "EWA Succeeded", // Done
    withdrawal_failure: "EWA Failed", // Done
    press_home_card_detail: "Home card detail", // Done
    load_vui_balance_detail: "Vui Balance Detail Loaded", // Done
    press_withdraw_from_balance_detail: "Vui Balance Withdraw Pressed", // Done
  },
  [ANALYTIC_CATEGORIES.LoanHistory]: {
    press_loan_history: "Loan History Started", // Done
    press_pending_trans: "Loan History: Pending Trans Selected", // Done
    view_pending_tran: "Loan History: Pending Tran Viewed", // Done
    commit_toggle_pending: "Loan History: Pending Trans Filtered",
    press_completed_trans: "Loan History: Completed Trans Selected", // Done
    view_completed_tran: "Loan History: Completed Tran Viewed", // Done
    commit_toggle: "Loan History: Salary Period Filtered", // Done
    press_salary_period_trans: "Loan History: Salary Period Selected", // Done
    view_salary_period_tran: "Loan History: Salary Period Trans Viewed", // Done
  },
  [ANALYTIC_CATEGORIES.Faq]: {
    press_faq: "FAQ Pressed", // Done
    view_detail: "FAQ: Question Viewed", // Done
  },
  [ANALYTIC_CATEGORIES.HelpDesk]: {
    press_help: "Help Desk Pressed", // Done
    press_hotline: "Hotline Pressed", // Done
    press_zalo: "Zalo Pressed", // Done
  },
  [ANALYTIC_CATEGORIES.TermCondition]: {
    view_toc: "TOC Viewed", // Done
  },
  [ANALYTIC_CATEGORIES.Setting]: {
    press_setting: "Setting Pressed", // Done
  },
  [ANALYTIC_CATEGORIES.EmployeeInfo]: {
    press_employee_info: "Employee Info Pressed", // Done
  },
  [ANALYTIC_CATEGORIES.Payslip]: {
    view_payslip: "Payslip Viewed", // Done
  },
  [ANALYTIC_CATEGORIES.Message]: {
    press_message: "Message Started", // Done
    press_detail_message: "Message Detail Pressed", // Done
  },
  [ANALYTIC_CATEGORIES.PhoneCard]: {
    press_phonecard: "PhoneCard Started", // Done
    press_paid_phonecard: "PhoneCard Paid Cards Viewed",
    select_carrier: "Phone Carrier Selected", // Done
    press_phonecard_button: "PhoneCard Money Button Pressed", // Done
    press_plus_phonecard: "PhoneCard Added", // Done
    press_minus_phonecard: "PhoneCard Minused", // Done
    press_continue_transaction: "PhoneCard Continued", // Done
    press_confirm_transaction: "PhoneCard Confirmed", // Done
    submit_password: "PhoneCard Password Submitted", // Done
    phonecard_success: "PhoneCard Succeeded", // Done
    phonecard_failure: "PhoneCard Failed", // Done
  },
  [ANALYTIC_CATEGORIES.TopUp]: {
    press_button: "TopUp Started", // Done
    select_carrier: "Phone Carrier Selected", // Done
    press_topup_button: "TopUp Money Button Pressed", // Done
    press_continue_transaction: "TopUp Continued", // Done
    press_confirm_transaction: "TopUp Confirmed", // Done
    submit_password: "TopUp Password Submitted", // Done
    topup_success: "TopUp Succeeded", // Done
    topup_failure: "TopUp Failed", // Done
  },
  [ANALYTIC_CATEGORIES.ForgotPassword]: {
    load_identification_confirm:
      "Forget Password Identification Confirm Loaded", // Done
    press_check_identification: "Forget Password Check Identification Pressed", // Done
    load_forget_password_otp: "Forget Password OTP Loaded", // Done
    press_otp_timeout_resend_forget_password:
      "Forget Password: OTP Timeout Resent", // Done
    create_password_forget_password: "Forget Password: Password Created", // Done
    confirm_password_forget_password: "Forget Password: Password Confirmed", // Done
    load_password_change_success: "Forget Password Change Success Loaded", // Done
  },
  [ANALYTIC_CATEGORIES.Voucher]: {
    press_voucher_detail: "Voucher Detail Viewed", // Done
    press_close_voucher_detail: "Voucher Detail Closed", // Done
    press_use_voucher: "Voucher Used", // Done
    press_cancel_voucher: "Voucher Canceled", // Done
    press_use_voucher_in_detail: "Voucher Used In Detail", // Done
    press_cancel_voucher_in_detail: "Voucher Canceled In Detail", // Done
  },
  [ANALYTIC_CATEGORIES.DataConsent]: {
    press_data_consent: "Data Consent Pressed", // Done
    data_consent_success: "Data Consent Succeeded", // Done
    data_consent_failure: "Data Consent Failed", // Done
  },
};

export function trackEvent<T extends ANALYTIC_CATEGORIES>(
  categoryName: T,
  event: keyof (typeof ANALYTIC_EVENT_NAMES)[T],
  properties?: EventProperties
) {
  analytics.track(ANALYTIC_EVENT_NAMES[categoryName][event] as SegmentEvent, {
    category: categoryName,
    action: event,
    ...properties,
  });
}
