/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PageContainer from "../components/layouts/PageContainer";
import PageHeader from "../components/PageHeader";
import PrimaryContainerButton from "../components/button/PrimaryContainerButton";
import {
  ApolloError,
  isApolloError,
  useMutation,
  useQuery,
} from "@apollo/client";
import { agreementQueryDocument } from "../apollo/queries/agreement.gql";
import { GenerateEmploymentAgreementMutation } from "../apollo/__generated__/graphql";
import {
  agreeToCMutationDocument,
  generateAgreementMutationDocument,
  requestOTPMutationDocument,
  signToCMutationDocument,
} from "../apollo/mutations/agreement.gql";
import { useNavigate } from "react-router-dom";
import { graphqlErrorParser } from "../helpers";
import { useGlobalLoaderService } from "../hooks/useGlobalLoaderService";
import { RoutePath } from "../router/path";
import {
  syncEmploymentFromExternalSourceMutationDocument,
  unlinkUserEmploymentMutationDocument,
} from "@/apollo/mutations/home.gql";
import { useToast } from "@/hooks/useToast";
import VuiLoading from "@/components/Lotties/Loading";
import { OTP_LENGTH } from "@/constants";
import { useAppState } from "@/hooks/useAppState";
import { ANALYTIC_CATEGORIES, trackEvent } from "@/helpers/events";
import SignToCBottomSheet, {
  BottomSheetRef,
} from "@/components/agreement/SignToCBottomSheet";
import * as Sentry from "@sentry/react";

export const AgreementPage = () => {
  const navigate = useNavigate();
  const { setState } = useAppState();
  const { showMessage } = useToast();

  const bsRef = useRef<BottomSheetRef>(null);

  const { data } = useQuery(agreementQueryDocument, {
    fetchPolicy: "cache-and-network",
  });

  const [signToCFunc] = useMutation(signToCMutationDocument);
  const [agreeToCFunc] = useMutation(agreeToCMutationDocument);
  const [requestOTPFunc] = useMutation(requestOTPMutationDocument);

  const [syncEmploymentFromExternalSourceMutation] = useMutation(
    syncEmploymentFromExternalSourceMutationDocument,
    {
      context: { timeout: 30000 },
    }
  );

  const [unlinkFunc] = useMutation(unlinkUserEmploymentMutationDocument);

  const employmentId = useMemo(
    () => data?.profile.currentEmployment?.id,
    [data?.profile.currentEmployment?.id]
  );

  const numberOfFails = useRef<number>(0);

  const { setGlobalLoading } = useGlobalLoaderService();

  const [error, setError] = useState<unknown>();

  const [generatedAgreement, setAgreement] =
    useState<
      GenerateEmploymentAgreementMutation["generateEmploymentAgreement"]
    >();

  const [generateFunc] = useMutation(generateAgreementMutationDocument);

  const handleClickTerm = useCallback(() => {
    navigate(RoutePath.TermAndCondition);
  }, [navigate]);

  const handleSign = useCallback(async () => {
    try {
      // tracker
      trackEvent(ANALYTIC_CATEGORIES.RegisterCredential, "press_agreement");

      await requestOTPFunc({
        variables: {
          otpLength: OTP_LENGTH,
        },
      });

      bsRef.current?.show();
    } catch (error) {
      showMessage.error(graphqlErrorParser(error), {
        id: "agreement-error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const manualGenerateAgreement = useCallback(async () => {
    try {
      if (numberOfFails.current >= 3) {
        // tracker
        trackEvent(ANALYTIC_CATEGORIES.RegisterCredential, "load_toc_failure");

        showMessage.error("Không thể tạo thoả thuận bổ sung", {
          duration: 10 * 60 * 1000, // 10 minutes
          id: "generate-agreement-error",
        });
        return;
      }
      const { data: generatedAgreementData } = await generateFunc({
        variables: {
          employmentId: employmentId as string,
          qualifier: "manual",
        },
      });

      // tracker
      trackEvent(ANALYTIC_CATEGORIES.RegisterCredential, "load_toc_success");

      setAgreement(generatedAgreementData?.generateEmploymentAgreement);
    } catch (error) {
      numberOfFails.current += 1;
      manualGenerateAgreement();
    }
  }, [employmentId, generateFunc, showMessage]);

  useEffect(() => {
    if (employmentId) {
      manualGenerateAgreement();
    }

    return () => {
      // closeSnackbar(); TODO hide to show OTP
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentId]);

  const handleNextForSignToc = useCallback(
    async (otp: string) => {
      try {
        if (!employmentId) {
          return;
        }

        // tracker
        trackEvent(ANALYTIC_CATEGORIES.RegisterCredential, "submit_otp_toc");

        setGlobalLoading(true);

        await Promise.all([
          agreeToCFunc({
            // agree to agreement
            variables: {
              employmentId: employmentId,
            },
            refetchQueries: [
              {
                query: agreementQueryDocument,
              },
            ],
            awaitRefetchQueries: true,
          }),
          signToCFunc({
            // sigh to agreement
            variables: {
              payload: {
                employmentId: employmentId,
                otp,
                otpLength: OTP_LENGTH,
              },
            },
          }),
        ]);

        await syncEmploymentFromExternalSourceMutation({
          variables: {
            id: employmentId,
          },
        });

        // tracker
        trackEvent(
          ANALYTIC_CATEGORIES.RegisterCredential,
          "link_employment_success"
        );

        setState((prevState) => ({
          ...prevState,
        }));

        navigate(RoutePath.Home, {
          replace: true,
        });
      } catch (error: any) {
        if (isApolloError(error as ApolloError)) {
          if (
            error.graphQLErrors[0]?.extensions &&
            error.graphQLErrors[0]?.extensions?.applicationCode ===
              "NGUYENKIM_EMPLOYEE_INFO_NOT_MATCHED"
          ) {
            showMessage.error(graphqlErrorParser(error), {
              id: "NGUYENKIM_EMPLOYEE_INFO_NOT_MATCHED",
            });

            // Direct send message to Sentry
            Sentry.captureMessage("User employment info not matched", {
              extra: {
                employmentId,
              },
            });

            await unlinkFunc({
              variables: {
                id: employmentId || "",
              },
            });
            navigate(RoutePath.Onboard);
            return;
          }
        }

        bsRef.current?.clearInput();
        setError(error);
      } finally {
        setGlobalLoading(false);
      }
    },
    [
      employmentId,
      setGlobalLoading,
      signToCFunc,
      agreeToCFunc,
      syncEmploymentFromExternalSourceMutation,
      setState,
      navigate,
      showMessage,
      unlinkFunc,
    ]
  );

  const handleAcceptToC = useCallback(async () => {
    // TODO tracker not defined
    try {
      await requestOTPFunc({
        variables: {
          otpLength: OTP_LENGTH,
        },
      });

      bsRef.current?.show();
    } catch (error) {
      setError(error);
    }
  }, [requestOTPFunc]);

  return (
    <>
      <PageContainer className="flex min-h-viewport flex-col">
        <PageHeader
          hideRightButton
          hideLeftButton
          title={"Thoả Thuận Bổ Sung"}
        />
        <div className="relative flex-1">
          {generatedAgreement?.agreement.agreementHtmlFile?.uri ? (
            <iframe
              className="aspect-auto absolute inset-0 h-full w-full p-2 pb-0"
              src={generatedAgreement?.agreement.agreementHtmlFile?.uri}
            />
          ) : (
            <>
              <div className="aspect-auto absolute inset-0 flex h-full w-full items-center justify-center">
                <VuiLoading />
              </div>
            </>
          )}
        </div>
        <div className="px-5 py-4">
          <div className="mb-4 font-normal">
            Bằng việc bấm Tiếp tục, bạn đồng ý với{" "}
            <span
              role="button"
              onClick={handleClickTerm}
              className="text-primary"
            >
              Điều khoản & Chính sách
            </span>{" "}
            của Vui App
          </div>

          <PrimaryContainerButton
            onClick={handleSign}
            className="active:bg-primary-dark"
            disabled={!generatedAgreement?.agreement.agreementHtmlFile?.uri}
          >
            <span className="text-base font-bold text-white group-disabled:text-[#B3B3B3]">
              Tiếp tục
            </span>
          </PrimaryContainerButton>
        </div>
      </PageContainer>
      <SignToCBottomSheet
        ref={bsRef}
        phoneNumber={data?.profile.phoneNumber || ""}
        onResend={handleAcceptToC}
        onEndEditing={handleNextForSignToc}
        error={error}
      />
    </>
  );
};

export default AgreementPage;
